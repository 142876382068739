import React from 'react'

function Services() {
  return (
    <>
    <section className="section services pt-0" id="services-sec">
      <div className="container">
        <div className="row justify-content-center text-center mb-4">
          <div className="col-5">
            <h3 className="h3 heading">Services</h3>
            <p>Areas of expertise include:</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3">
            {/* <i className="bi bi-card-checklist"></i> */}
            <img src={require("../assets/img/react-icon-modified.webp")} width="50"/>
            <h4 className="h4 mb-2">React JS</h4>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p> */}
            <ul className="list-unstyled list-line">
              <li>Development of interactive and responsive user interfaces utilizing ReactJS</li>
              <li>SCSS, Styled Components, Bootstrap/TailwindCSS</li>
              <li>Rest API Integration with ReactJS.</li>
              <li>State management with Context, Redux Toolkit</li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3">
            {/* <i className="bi bi-binoculars"></i> */}
            <img src={require("../assets/img/shopify-icon-modified.webp")} width="50"/>
            <h4 className="h4 mb-2">Shopify Plus</h4>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p> */}
            <ul className="list-unstyled list-line">
              <li>Shopify Themes development.</li>
              <li>Shopify Admin/Storefront APIs Integrations</li>
              <li>E-mail, SMS, Subscription configuration.</li>
              <li>Figma to Shopify Store Conversion.</li>
              <li>Checkout UI Extensions development.</li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3">
            {/* <i className="bi bi-brightness-high"></i> */}
            <img src={require("../assets/img/hubspot-icon-modified.webp")} width="50"/>
            <h4 className="h4 mb-2">Hubspot CMS</h4>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p> */}
            <ul className="list-unstyled list-line">
              <li>HubSpot Landing Page Development</li>
              <li>HubSpot Blog or Email Template Development</li>
              <li>HubL Code Customizations</li>
              <li>HubSpot Custom Module Development</li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3">
            {/* <i className="bi bi-calendar4-week"></i> */}
            <img src={require("../assets/img/wordpress-icon-modified.webp")} width="50"/>
            <h4 className="h4 mb-2">WordPress</h4>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p> */}
            <ul className="list-unstyled list-line">
              <li>WordPress Site Development using page builders like WpBakery, Elementor.</li>
              <li>Custom Template Development Using Custom Post Type / ACF.</li>
              <li>Figma To Wordpress Site Conversion.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Services